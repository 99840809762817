import { createMuiTheme } from '@material-ui/core/styles';
import createPalette from '@material-ui/core/styles/createPalette';
import createTypography from '@material-ui/core/styles/createTypography';

export let theme = createMuiTheme();

declare module '@material-ui/core/styles/createPalette' {
  interface PaletteOptions {
    custom: {
      dashboardBg: React.CSSProperties['color'];
      grayDark: React.CSSProperties['color'];
      ultralight: React.CSSProperties['color'];
    };
  }
}

const colors = {
  primary: {
    main: '#39C7DE',
    dark: '#1cb2ca',
    light: 'rgba(57,199,222,0.5)',
    contrastText: '#FFF',
    error: '#f44336',
  },
  custom: {
    dashboardBg: '#F9FAFB',
    grayDark: '#526D7A',
    grayLight: '#ABB5BA',
    ultralight: '#ECFAFC',
    orange: '#FF7D5C',
    black: '#1B2A32',
  },
  secondary: {
    main: '#FF4B00',
    medium: '#FF7D5C',
    dark: '#CC3C00',
    light: 'rgba(255,75,0,0.5)',
    contrastText: '#FFF',
  },
};

theme.palette = createPalette(colors);

theme.typography = createTypography(theme.palette, {
  fontFamily: '-apple-system, BlinkMacSystemFont, HelveticaNeue, sans-serif',
  fontSize: 15,
  h1: {
    fontSize: '1.5rem',
    lineHeight: '2rem',
    fontWeight: 800,
    letterSpacing: '0.15px',
    color: colors.custom.grayDark,
  },
  h2: {
    fontSize: '1.25rem',
    lineHeight: '1.5rem',
    fontWeight: 800,
    letterSpacing: '0.15px',
    color: colors.custom.grayDark,
  },
  h3: {
    fontSize: '1.1rem',
    lineHeight: '1.5rem',
    fontWeight: 700,
    letterSpacing: '0.7px',
    color: colors.custom.grayDark,
  },
  body1: {
    fontSize: 15,
  },
  body2: {
    fontSize: 12,
    color: colors.custom.grayDark,
    overflowWrap: 'break-word',
  },
});

theme.shadows = [
  'none',
  '0px 4px 16px rgba(61, 116, 143, 0.1)',
  '0px 4px 16px rgba(61, 116, 143, 0.1)',
  '0px 4px 16px rgba(61, 116, 143, 0.1)',
  '0px 4px 16px rgba(61, 116, 143, 0.1)',
  '0px 4px 16px rgba(61, 116, 143, 0.1)',
  '0px 4px 16px rgba(61, 116, 143, 0.1)',
  '0px 4px 16px rgba(61, 116, 143, 0.1)',
  '0px 4px 16px rgba(61, 116, 143, 0.1)',
  '0px 4px 16px rgba(61, 116, 143, 0.1)',
  '0px 4px 16px rgba(61, 116, 143, 0.1)',
  '0px 4px 16px rgba(61, 116, 143, 0.1)',
  '0px 4px 16px rgba(61, 116, 143, 0.1)',
  '0px 4px 16px rgba(61, 116, 143, 0.1)',
  '0px 4px 16px rgba(61, 116, 143, 0.1)',
  '0px 4px 16px rgba(61, 116, 143, 0.1)',
  '0px 4px 16px rgba(61, 116, 143, 0.1)',
  '0px 4px 16px rgba(61, 116, 143, 0.1)',
  '0px 4px 16px rgba(61, 116, 143, 0.1)',
  '0px 4px 16px rgba(61, 116, 143, 0.1)',
  '0px 4px 16px rgba(61, 116, 143, 0.1)',
  '0px 4px 16px rgba(61, 116, 143, 0.1)',
  '0px 4px 16px rgba(61, 116, 143, 0.1)',
  '0px 4px 16px rgba(61, 116, 143, 0.1)',
  '0px 4px 16px rgba(61, 116, 143, 0.1)',
];

theme.overrides = {
  MuiPaper: {
    rounded: {
      borderRadius: 8,
    },
  },
  MuiAppBar: {
    colorPrimary: {
      backgroundColor: '#fff',
      zIndex: 1300,
    },
  },
  MuiToolbar: {
    gutters: {
      [theme.breakpoints.up('sm')]: {
        paddingLeft: 32,
        paddingRight: 32,
      },
    },
  },
  MuiCheckbox: {
    checked: {
      '& svg': {
        color: theme.palette.primary.main,
      },
    },
  },
  MuiRadio: {
    checked: {
      '& svg': {
        color: theme.palette.primary.main,
      },
    },
  },
  MuiTabs: {
    root: {
      minHeight: 12,
      color: '#ABB5BA',
    },
    indicator: {
      height: 1,
      backgroundColor: theme.palette.primary.main,
    },
  },
  MuiTab: {
    root: {
      [theme.breakpoints.up('xs')]: {
        minWidth: 'auto',
        textTransform: 'uppercase',
        fontSize: 11,
        padding: '3px 0',
        marginRight: 24,
        minHeight: 12,
        '&:last-child': {
          marginRight: 0,
        },
      },
    },
    textColorPrimary: {
      color: '#ABB5BA',
    },
    selected: {
      color: theme.palette.primary.main,
      fontWeight: 'bold',
    },
  },
  MuiInputBase: {
    input: {
      fontSize: '15px',
    },
  },
  MuiOutlinedInput: {
    input: {
      '&:disabled': {
        backgroundColor: '#f5f5f5',
        color: '#000000',
        borderRadius: 6,
      },
    },
  },
  MuiInputLabel: {
    outlined: {
      backgroundColor: '#ffffff',
      borderRadius: 10,
    },
  },
  MuiButton: {
    sizeSmall: {
      paddingLeft: '35px',
      paddingRight: '35px',
    },
    contained: {
      backgroundColor: '#ABB5BA',
      color: theme.palette.primary.contrastText,
      '&:hover': {
        backgroundColor: '#526D7A',
      },
      '&:disabled': {
        backgroundColor: '#D5DADD',
        '& *': {
          color: theme.palette.primary.contrastText,
        },
      },
    },
    outlined: {
      border: '1px solid #526D7A',
      color: '#526D7A',
      '&:hover': {
        border: '1px solid #1B2A32',
        color: '#1B2A32',
      },
      '&:disabled': {
        border: '1px solid #D5DADD',
        '& *': {
          color: '#D5DADD',
        },
      },
    },
    text: {
      paddingLeft: '9px !important',
      paddingRight: '9px !important',
      color: '#526D7A',
      '&:hover': {
        color: '#1B2A32',
      },
      '&:disabled': {
        '& *': {
          color: '#D5DADD',
        },
      },
    },
    containedPrimary: {
      '&:disabled': {
        background: theme.palette.primary.light,
        '& *': {
          color: theme.palette.primary.contrastText,
        },
      },
    },
    containedSecondary: {
      '&:disabled': {
        background: theme.palette.secondary.light,
        '& *': {
          color: theme.palette.secondary.contrastText,
        },
      },
    },
    outlinedPrimary: {
      '&:disabled': {
        borderColor: theme.palette.primary.light,
        '& *': {
          color: theme.palette.primary.light,
        },
      },
      '&:hover': {
        color: theme.palette.primary.dark,
      },
    },
    outlinedSecondary: {
      '&:disabled': {
        borderColor: theme.palette.secondary.light,
        '& *': {
          color: theme.palette.secondary.light,
        },
      },
      '&:hover': {
        color: theme.palette.secondary.dark,
      },
    },
  },
  MuiIconButton: {
    disabled: {
      '& *': {
        color: '#D5DADD',
      },
    },
  },
  MuiDrawer: {
    paper: {
      border: 'none !important',
      boxShadow: theme.shadows[1],
    },
  },
  MuiListItem: {
    gutters: {
      [theme.breakpoints.up('sm')]: {
        paddingLeft: 32,
        paddingRight: 32,
      },
    },
  },
  MuiSvgIcon: {
    root: {
      color: '#ABB5BA',
    },
  },
  MuiTableSortLabel: {
    icon: {
      opacity: 0.3,
      color: '#ABB5BA',
    },
  },
  MuiTooltip: {
    tooltip: {
      backgroundColor: theme.palette.common.white,
      color: '#1B2A32;',
      boxShadow: '0px 2px 8px rgba(61, 116, 143, 0.2)',
      fontSize: 12,
      fontWeight: 'normal',
      lineHeight: '16px',
      padding: 24,
    },
    arrow: {
      color: theme.palette.common.white,
    },
  },
  MuiTable: {
    root: {
      borderRadius: '8px',
    },
  },
  MuiTableRow: {
    root: {
      cursor: 'pointer',
      '&:nth-of-type(odd)': {
        backgroundColor: '#F9FAFB',
      },
      '&:hover': {
        backgroundColor: '#ECFAFC !important',
      },
    },
    head: {
      cursor: 'inherit',
    },
  },
  MuiTableCell: {
    root: {
      borderBottom: 'none',
      padding: '16px 40px',
      whiteSpace: 'pre-line',
    },
    head: {
      fontSize: '12px',
      lineHeight: '16px',
      color: '#526D7A',
      background: '#fff',
    },
  },
  MuiStepper: {
    alternativeLabel: {
      padding: 0,
      background: 'transparent',
    },
  },
  MuiStep: {
    alternativeLabel: {
      flex: 'inherit',
      '&:after': {
        content: "''",
        position: 'absolute',
        width: '50%',
        height: '1px',
        top: '12px',
        background: '#D5DADD',
        left: 0,
        zIndex: -1,
      },
      '&:before': {
        content: "''",
        position: 'absolute',
        width: '50%',
        height: '1px',
        top: '12px',
        background: '#D5DADD',
        right: 0,
        zIndex: -1,
      },
      '&:first-child': {
        '&:after': {
          display: 'none',
        },
      },
      '&:last-child': {
        '&:before': {
          display: 'none',
        },
      },
    },
  },
  MuiStepLabel: {
    label: {
      textTransform: 'uppercase',
      fontSize: 11,
      color: '#526D7A',
      '&.MuiStepLabel-active': {
        color: theme.palette.primary.main,
      },
    },
  },
  MuiStepConnector: {
    line: {
      display: 'none',
    },
  },
  MuiStepIcon: {
    root: {
      color: '#ABB5BA',
      background: '#fff',
      borderRadius: '50%',
    },
  },
  MuiSelect: {
    select: {
      '&:focus': {
        backgroundColor: 'transparent',
      },
    },
    icon: {
      color: '#ABB5BA',
    },
  },
  MuiDialog: {
    paper: {
      padding: 24,
    },
  },
  MuiDialogTitle: {
    root: {
      margin: 0,
      padding: 0,
    },
  },
  MuiDialogContent: {
    root: {
      padding: 0,
    },
  },
  MuiDialogActions: {
    root: {
      padding: 0,
      marginTop: 24,
    },
  },
  MuiChip: {
    root: {
      margin: '4px',
      padding: '16px 0 16px 8px',
      fontSize: '15px',
      lineHeight: '24px',
      color: ' #526D7A',
      backgroundColor: '#D5DADD',
    },
    deleteIcon: {
      width: 24,
      height: 24,
      color: '#ABB5BA',
    },
  },
  MuiTablePagination: {
    root: {
      fontSize: '0.75rem',
      color: colors.custom.grayDark,
      display: 'flex',
      flexGrow: 1,
      overflow: 'hidden',
    },
    toolbar: {
      display: 'flex',
      alignItems: 'center',
    },
    caption: {
      fontSize: '0.75rem',
    },
    select: {
      fontSize: '0.75rem',
      padding: '2px 24px 2px 0',
    },
    selectIcon: {
      fontSize: '20px',
      marginTop: '2px',
    },
    spacer: {
      display: 'none',
    },
  },
  MuiSwitch: {
    switchBase: {
      color: '#F1F3F4',
    },
    track: {
      backgroundColor: colors.custom.grayLight,
    },
  },
  //@ts-ignore
  MUIRichTextEditor: {
    root: {
      width: '100%',
      minHeight: 140,
      '& svg': {
        color: 'inherit',
      },
    },
    container: {
      margin: 0,
    },
    toolbar: {
      borderBottom: `1px solid #ebebeb`,
      padding: 4,
      background: colors.custom.ultralight,
      borderRadius: '8px 8px 0 0',
    },
    editor: {
      padding: '4px 16px',
      '& ol, ul': {
        marginLeft: 32,
      },
      '& ul': {
        listStyle: 'inherit',
      },
    },
    editorContainer: {
      minHeight: 60,
    },
    placeHolder: {
      paddingLeft: 16,
    },
  },
  MuiLinearProgress: {
    colorPrimary: {
      backgroundColor: '#ffffff',
    },
    barColorPrimary: {
      backgroundColor: '#00AE96',
    },
    colorSecondary: {
      backgroundColor: '#ffffff',
    },
    barColorSecondary: {
      backgroundColor: '#F44336',
    },
  },
};

console.log({ theme });
